import {
  Checkbox,
  DefaultButton,
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  Stack,
} from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { IConfirmationPaiment } from "../../Models/IConfirmationPaiment";
import { IPaiementPanier } from "../../Models/IPaiementPanier";
import { IReservationCart } from "../../Models/IReservationCart";
import CurrencyService from "../../Services/CurrencyService/CurrencyService";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { ConfirmationPayment } from "../ConfirmationPayment";
import { ModalWithSimpleBanner } from "../ModalWithSimpleBanner";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { Payment } from "../Payment";
import { ICartProps, ICartState } from "./Cart.types";

export class CartComponent extends React.Component<ICartProps, ICartState> {

  private PANEL_CART = 0;
  private PANEL_CREDITCARD_FORM = 1;
  private PANEL_PAIEMENT_APPROVED = 2;

  private _iconButtonStyles = {
    root: {
      color: this.props.theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
      width: "10px",
    },
    rootHovered: {
      color: this.props.theme.palette.neutralDark,
    },
  };
  constructor(props: ICartProps) {
    super(props);
    this.state = {
      statesComponent: this.PANEL_CART,
      showModal: false,
      items: null,
      isLoading: true,
      itemSelectId: "",
      conditionIsCheck: false,
      paiementPanier: {
        nomCarte: null,
        numeroCarte: "",
        codeValidation: "",
        expiration: "",
        montantPayer: 0,
      },
      expirationDateMonth: null,
      expirationDateYear: null,
      confirmationPaiment: null,
      showModalError: false,
      statusError: 0,
      panierExpireLe: null,
      refreshCartRequired: false
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("Cart", true);
    let items = await ReservationServices.GetItemPanier();
    this.props.setEstReloadRequis(items.estReservationExpirer || items.estSurreservation);    

    this.setState({ items: items, isLoading: false });
    LoadingServices.setLoading("Cart", false);
  }

  async onPaiementClick()
  {
    let demarrerPaiementResult = await ReservationServices.DemarrerPaiement();
    if (!demarrerPaiementResult.succes) {
      this.setState({'refreshCartRequired': true})
    } 
      this.setState({'panierExpireLe': demarrerPaiementResult?.data?.expireLe}, ()=>{
        this.setState({'statesComponent': this.PANEL_CREDITCARD_FORM}) 
      })
    
  }

  render(): JSX.Element {
    if (this.state.isLoading) {
      return <Stack id="1"></Stack>;
    } else if (this.state.items.reservations.length === 0) {
      return (
        <Stack>
          {this.renderMessageBar()}
          <h1>{i18n.t("Cart:Title:NoItem")}</h1>

          {this.renderBtn()}
        </Stack>
      );
    } else {
      return (
        <Stack>
          {this.renderMessageBar()}
          {this.renderComponent()}
        </Stack>
      );
    }
  }

  renderBtn() {
    return (
      <Stack style={{ width: "95%" }}>
        {this.state.items.reservations.length === 0 ? (
          ""
        ) : (
          <Stack
            horizontal
            style={{ marginTop: "20px" }}
            verticalAlign="center"
          >
            <Checkbox
              checked={this.state.conditionIsCheck}
              onChange={(e, v) => {
                this.setState({ conditionIsCheck: v });
              }}
            />

            <Label
              onClick={() => {
                this.setState({
                  conditionIsCheck: !this.state.conditionIsCheck,
                });
              }}
              style={{ cursor: "pointer" }}
            >
              {i18n.t("Cart:Label:Checkbox1")}{" "}
              <a
                href={
                  i18n.getLanguage() === "fr"
                    ? "/pdf/RNI_Conditions_Francais.pdf"
                    : "/pdf/RNI_Conditions_Anglais.pdf"
                }
                target={"_blank"}
              >
                {i18n.t("Cart:Label:Checkbox2")}{" "}
              </a>
              {i18n.t("Cart:Label:Checkbox3")}
            </Label>
          </Stack>
        )}

        <Stack style={{ marginTop: "20px", width: "95%" }} horizontal>
          <Link to="/reservationpassengervehicle">
            <DefaultButton
              style={{
                color: "white",
                backgroundColor: this.props.theme.palette.blueDark,
                borderRadius: 10,
                height: "50px",
                width: this.props.mobile ? "170px" : "250px",
                marginRight: "20px",
              }}
            >
              {i18n.t("Cart:Label:AddReservation")}
            </DefaultButton>
          </Link>

          <DefaultButton
            disabled={!this.state.conditionIsCheck || this.state.items.voyageSureservationInfos.length > 0}
            style={{
              color: "white",
              backgroundColor: this.state.conditionIsCheck && this.state.items.voyageSureservationInfos.length == 0
                ? this.props.theme.palette.blueDark
                : this.props.theme.palette.neutralLight,
              borderRadius: 10,
              height: "50px",
              width: this.props.mobile ? "170px" : "250px",
            }}
            onClick={() => {
              this.onPaiementClick()
            }}
          >
            {i18n.t("Cart:Label:Paiement")}
          </DefaultButton>
        </Stack>
      </Stack>
    );
  }

  renderMessageBar() {
    if (this.props.estReloadRequis) {
      return (
        <Stack>
          <MessageBar messageBarType={MessageBarType.severeWarning}>
          {this.state.items.estReservationExpirer && i18n.t("Cart:Title:MsgBar")}
          {this.state.items.estSurreservation && i18n.t("Cart:Title:MsgBarOverbook")}
          </MessageBar>
        </Stack>
      );
    }
  }

  renderOverBookingMessageBar() {
    return (
      <Stack>
        <MessageBar messageBarType={MessageBarType.severeWarning}>
          {i18n.t("Cart:Title:OverBookingMsgBar")}
        </MessageBar>
      </Stack>
    );    
  }

  renderSurreservationMessage(){    
    return (<>
    <Stack>
      {this.state.items.voyageSureservationInfos.map
      (
        (p,i)=>
          <span key={i} style={{color:"red"}}>
            {i18n.t("RPV:Message:OverbookMustChoose1")}
            {p.nombrePlaceDispo}
            {i18n.t("RPV:Message:OverbookMustChoose2")}
            {p.noVoyage}
            {i18n.t("RPV:Message:OverbookMustChoose3")}
          </span>
      )}
    </Stack></>)

  }

  renderComponent(): JSX.Element {
    switch (this.state.statesComponent) {
      case (this.PANEL_CART):
        return (
          <Stack id="2" style={{ marginBottom: "100px", marginLeft: "20px" }}>
            {this.renderModal()}
            <Stack horizontalAlign="center">
              <h1 style={{ textDecoration: "underline" }}>
                {i18n.t("Cart:Title:MyCart")}
              </h1>
            </Stack>
            {this.renderSurreservationMessage()}
            {this.state.items.reservations.map((item, index) => {
              return this.renderTicket(item, index);
            })}
            {this.renderSommaire()}
            {this.renderBtn()}
          </Stack>
        );
        break;
      case (this.PANEL_CREDITCARD_FORM):        
        return (
          <Stack>
            {this.state.refreshCartRequired && this.renderOverBookingMessageBar()}
            {this.renderModalError()}
            {!this.state.refreshCartRequired &&
            <Payment
              updateCreditCard={(value) => {
                this.state.paiementPanier.numeroCarte = value;
                this.setState({ ...this.state });
              }}
              updateVerificationCode={(value) => {
                this.state.paiementPanier.codeValidation = value;
                this.setState({ ...this.state });
              }}
              updateExpirationDateMonth={(value) => {
                this.setState({ expirationDateMonth: value });
              }}
              updateExpirationDateYear={(value) => {
                this.setState({ expirationDateYear: value });
              }}
              updateNomCarte={(value) => {
                this.state.paiementPanier.nomCarte = value;
                this.setState({ ...this.state });
              }}
              montantTotal={this.state.items.panierTotaux.total}
              {...this.props}
              updateStateComponent={async () => this.payerPanier()}
              canBePay={this.validationPayment()}
              panierExpireLe={this.state.panierExpireLe}
            />}
            {this.state.refreshCartRequired &&  
              <DefaultButton
              style={{
                color: this.props.theme.palette.white,
                backgroundColor: this.props.theme.palette.blueDark,
                borderRadius: 10,
                height: "50px",
                width: this.props.mobile ? "170px" : "250px",
                margin: "100px auto"
              }}
              onClick={() => {
                window.location.reload();
              }}
            >{i18n.t("Cart:Button:ReturnToCart")}</DefaultButton>}
          </Stack>
        );
      case (this.PANEL_PAIEMENT_APPROVED):
        return (
          <ConfirmationPayment
            confirmationPaiment={this.state.confirmationPaiment}
            {...this.props}
          />
        );
      default:
        break;
    }
  }

  validationPayment() {
    let codeValidation = this.state.paiementPanier.codeValidation.replaceAll(
      "_",
      ""
    );
    let numeroCarte = this.state.paiementPanier.numeroCarte
      .replaceAll("_", "")
      .replaceAll(" ", "");

    return (
      this.state.expirationDateMonth !== null &&
      this.state.expirationDateYear !== null &&
      codeValidation.length === 3 &&
      numeroCarte.length === 16 &&
      this.state.paiementPanier.nomCarte !== null
    );
  }

  async payerPanier() {
    LoadingServices.setLoading("payerPanier", true);
    let pp: IPaiementPanier = {
      nomCarte: this.state.paiementPanier.nomCarte,
      numeroCarte: this.state.paiementPanier.numeroCarte,
      codeValidation: this.state.paiementPanier.codeValidation,
      montantPayer: this.state.items.panierTotaux.total,
      expiration:
        this.state.expirationDateYear.substring(2, 4) +
        "/" +
        this.state.expirationDateMonth.padStart(2, "0").substring(0, 2),
    };

    let result = await ReservationServices.PayerPanier(pp);

    if (result.status < 299) {
      let confirmationPaiment: IConfirmationPaiment = result.data
        .data as IConfirmationPaiment;
      this.setState({
        statesComponent: this.PANEL_PAIEMENT_APPROVED,
        confirmationPaiment: confirmationPaiment,
      });
    } else if (result.status > 399 && result.status < 499) {
      this.setState({ showModalError: true, statusError: 400 });
    } else {
      this.setState({ showModalError: true, statusError: 500 });
    }
    LoadingServices.setLoading("payerPanier", false);
  }

  async deleteItem(id: string) {
    let result = await ReservationServices.DeleteItem(id);
    if (result < 299) {
      window.location.reload();
    }
  }

  renderHeader(item: IReservationCart, index: number) {
    if (this.props.mobile) {
      return (
        <Stack horizontal horizontalAlign="space-between" key={index}>
          <Stack>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
              {item.noVoyage}
            </Label>
            <Label>
              {item.origine} - {item.destination}
            </Label>
            <Label>({item.trajet})</Label>
          </Stack>
          <Stack style={{ marginRight: "20px" }} id={item.id + "x2b"}>
            <IconButton
              style={{ marginLeft: "20px" }}
              iconProps={{ iconName: "trash" }}
              styles={this._iconButtonStyles}
              onClick={() => {
                this.setState({ showModal: true, itemSelectId: item.id });
              }}
            />
          </Stack>
        </Stack>
      );
    } else {
      return (
        <Stack horizontal horizontalAlign="space-between" key={index}>
          <Stack horizontal={!this.props.mobile}>
            <Label>{item.noVoyage} -</Label>
            <Label>{item.origine} - </Label>
            <Label>{item.destination} &nbsp;</Label>
            <Label>({item.trajet})</Label>
          </Stack>
          <Stack style={{ marginRight: "20px" }} id={item.id + "x2b"}>
            <IconButton
              style={{ marginLeft: "20px" }}
              iconProps={{ iconName: "trash" }}
              styles={this._iconButtonStyles}
              onClick={() => {
                this.setState({ showModal: true, itemSelectId: item.id });
              }}
            />
          </Stack>
        </Stack>
      );
    }
  }

  renderTicket(item: IReservationCart, index: number) {
    return (
      <Stack key={index}>
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={item.noVoyage}
          render={
            <Stack style={{ marginLeft: "20px", marginBottom: "25px" }}>
              {this.renderHeader(item, index)}

              {item.passagers.length > 0 ? (
                <Stack horizontal={!this.props.mobile}>
                  <Label
                    style={{
                      fontWeight: "bold",
                      minWidth: !this.props.mobile ? "175px" : "",
                    }}
                  >
                    {i18n.t("Cart:Label:Passager")}
                  </Label>

                  <Stack style={{ marginLeft: "20px" }}>
                    {item.passagers.map((x, index) => {
                      return this.renderInfo(x.cout, x.passagerLibelle, index);
                    })}
                  </Stack>
                </Stack>
              ) : (
                ""
              )}

              {item.vehicules.length > 0 ? (
                <Stack horizontal={!this.props.mobile}>
                  <Label
                    style={{
                      fontWeight: "bold",
                      minWidth: !this.props.mobile ? "175px" : "",
                    }}
                  >
                    {i18n.t("Cart:Label:Vehicule")}
                  </Label>

                  <Stack style={{ marginLeft: "20px" }}>
                    {item.vehicules.map((x, index) => {
                      return this.renderInfo(x.cout, x.vehiculeLibelle, index);
                    })}
                  </Stack>
                </Stack>
              ) : (
                ""
              )}

              {item.services.length > 0 ? (
                <Stack horizontal={!this.props.mobile}>
                  <Label
                    style={{
                      fontWeight: "bold",
                      minWidth: !this.props.mobile ? "175px" : "",
                    }}
                  >
                    {item.services.length == 1
                      ? i18n.t("Cart:Label:SS")
                      : i18n.t("Cart:Label:SSS")}
                  </Label>

                  <Stack style={{ marginLeft: "20px" }}>
                    {item.services.map((x, index) => {
                      return this.renderInfo(x.cout, x.serviceLibelle, index);
                    })}
                  </Stack>
                </Stack>
              ) : (
                ""
              )}
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderInfo(cout: number, libelle: string, index: number) {
    return (
      <Stack
        horizontal
        key={index}
        horizontalAlign="space-between"
        style={{ width: "100%" }}
      >
        <Label
          style={{
            maxWidth: this.props.mobile ? "70%" : "100%",
            minWidth: this.props.mobile ? "70%" : "500px",
          }}
        >
          {libelle}
        </Label>
        <Label
          style={{
            marginRight: this.props.mobile ? "10px" : "",
          }}
        >
          {CurrencyService.ToDollars(cout)}
        </Label>
      </Stack>
    );
  }

  renderModalError() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            window.location.reload();
          }}
          redirectUrlToClose={"/cart"}
          {...this.props}
          isOpen={this.state.showModalError}
          render={
            <Stack style={{ marginLeft: "25px", marginRight: "25px" }}>
              {this.state.statusError === 400 ? (
                <Stack>
                  <p>{i18n.t("Cart:Modal:Error400")}</p>
                </Stack>
              ) : (
                <Stack>
                  <p>{i18n.t("Cart:Modal:Error500")}</p>
                </Stack>
              )}
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderModal() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({ showModal: false });
          }}
          redirectUrlToClose={"/cart"}
          {...this.props}
          isOpen={this.state.showModal}
          render={
            <Stack style={{ marginLeft: "25px" }}>
              <h3 style={{ borderBottom: "solid", marginRight: "25px" }}>
                {i18n.t("Cart:Title:DeleteItem")}
              </h3>
              <p>{i18n.t("Cart:Title:DeleteItemQuestion")}</p>

              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginRight: "75px", marginLeft: "50px" }}
              >
                <DefaultButton
                  style={{
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "40px",
                    width: "150px",
                  }}
                  onClick={() => {
                    this.setState({ showModal: false, itemSelectId: "" });
                  }}
                >
                  {i18n.t("Cart:Label:Non")}
                </DefaultButton>
                <DefaultButton
                  style={{
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "40px",
                    width: "150px",
                  }}
                  onClick={async () => {
                    this.deleteItem(this.state.itemSelectId);
                  }}
                >
                  {i18n.t("Cart:Label:Yes")}
                </DefaultButton>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderSommaire() {
    return (
      <Stack
        style={{
          border: "1px solid black",
          borderRadius: 10,
          width: "95%",
          marginTop: "30px",
          backgroundColor: this.props.theme.palette.blueDark,
          height: this.props.mobile ? "" : "50px",
        }}
        horizontal={!this.props.mobile}
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack style={{ marginLeft: "20px" }} horizontal>
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Cart:Label:SousTotal")}
          </Label>
          <Label style={{ color: "white", marginLeft: "15px" }}>
            {CurrencyService.ToDollars(this.state.items.panierTotaux.sousTotal)}
          </Label>
        </Stack>
        <Stack style={{ marginLeft: "20px" }} horizontal>
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Cart:Label:Tps")}
          </Label>
          <Label style={{ color: "white", marginLeft: "15px" }}>
            {CurrencyService.ToDollars(this.state.items.panierTotaux.tps)}
          </Label>
        </Stack>
        <Stack style={{ marginLeft: "20px" }} horizontal>
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Cart:Label:Tvq")}
          </Label>
          <Label style={{ color: "white", marginLeft: "15px" }}>
            {CurrencyService.ToDollars(this.state.items.panierTotaux.tvq)}
          </Label>
        </Stack>
        <Stack style={{ marginLeft: "20px", marginRight: "20px" }} horizontal>
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Cart:Label:Total")}
          </Label>
          <Label style={{ color: "white", marginLeft: "15px" }}>
            {CurrencyService.ToDollars(this.state.items.panierTotaux.total)}
          </Label>
        </Stack>
      </Stack>
    );
  }
}
