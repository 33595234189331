import { DefaultButton, IComboBoxOption, Label, Stack } from "@fluentui/react";
import * as React from "react";
import { Navigate } from "react-router";
import Cookies from "universal-cookie";
import { IMiniPassenger } from "../../Models/IMiniPassenger";
import { IParametreSurreservation } from "../../Models/IParametreSurreservation";
import { IVehiculeSelectedReservation } from "../../Models/IVehiculeSelectedReservation";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { VoyageServices } from "../../Services/VoyageServices";
import { InformationItinerary } from "../InformationItinerary";
import { ModalWithSimpleBanner } from "../ModalWithSimpleBanner";
import { MyPassengers } from "../MyPassengers";
import { ReservationVehicle } from "../ReservationVehicle";
import { ServicesSupplementaire } from "../ServicesSupplementaire";
import { Ticketing } from "../Ticketing";
import {
  IReservationPassengerVehicleProps,
  IReservationPassengerVehicleState,
} from "./ReservationPassengerVehicle.types";
import { PortServices } from "../../Services/PortServices";

export class ReservationPassengerVehicleComponent extends React.Component<
  IReservationPassengerVehicleProps,
  IReservationPassengerVehicleState
> {
  private _ssc: string[] = [];
  private _vsr: IVehiculeSelectedReservation[] = [];
  private _mp: IMiniPassenger[] = [];

  private PANEL_PORT_DEPART = 0;
  private PANEL_PSG_VHC_SVC = 1;
  private PANEL_PSG_ONLY = 2;
  private PANEL_VHC_ONLY = 3;
  private PANEL_SVC_ONLY = 4;
  private PANEL_SOMMAIRE = 5;


  constructor(props: IReservationPassengerVehicleProps) {
    super(props);
    this.state = {
      reservationIsDone: false,
      statesComponent: 0,
      redirect: false,
      portOrigine: undefined,
      portDestination: undefined,
      estAllerRetour: false,
      dateDeDepart: undefined,
      dateDeDeparts: [],
      miniPassengerSelected: [],
      servicesSuppCode: [],
      vehicleSelectedReservation: [],
      tutor: "",
      onlyBaby: false,
      onlyTeen: false,
      isLoading: true,
      informationSurreservation: null,      
      showModalError: false,
      modalErrorMsg: "",
      portOrigineDisponible: null,
      portDestinationDisponible: null
    };
  }

  render(): JSX.Element {
    if (this.state.redirect) {
      return <Navigate to="/" />;
    } else if (this.state.reservationIsDone) {
      return <Navigate to="/cart" />;
    } else {
      return this.props.mobile ? this.renderMobile() : this.renderDesktop();
    }
  }

  renderMobile(): JSX.Element {
    return (
      <Stack
        style={{
          marginLeft: "20px",
          height: "80vh",
        }}
      >
        {this.renderComponent(this.state.statesComponent)}

        {this.state.isLoading ? "" : this.renderBtn()}
      </Stack>
    );
  }

  renderDesktop(): JSX.Element {
    return (
      <Stack
        style={{
          marginLeft: "20px",
          height: "80vh",
        }}
      >
        {this.renderComponent(this.state.statesComponent)}
        {this.state.isLoading ? "" : this.renderBtn()}
      </Stack>
    );
  }

  renderBtn() {
    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{
          width: "95%",
          height: "100%",
          minHeight: "200px",
          marginTop: "20px",
        }}
      >
        <Stack>
          {this.state.statesComponent < 1 ? (
            <DefaultButton
              style={{
                color: "white",
                backgroundColor: this.props.theme.palette.blueDark,
                borderRadius: 10,
                height: "50px",
                width: this.props.mobile ? "150px" : "250px",
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              {i18n.t("RPV:btn:Recommencer")}
            </DefaultButton>
          ) : (
            <DefaultButton
              style={{
                color: "white",
                backgroundColor: this.props.theme.palette.blueDark,
                borderRadius: 10,
                height: "50px",
                width: this.props.mobile ? "150px" : "250px",
              }}
              onClick={() => {
                this.setState({
                  statesComponent: this.getPreviousStepPanelNumber(this.state.statesComponent),
                });
              }}
            >
              {i18n.t("RPV:btn:Retour")}
            </DefaultButton>
          )}
        </Stack>

        <Stack>
          {" "}
          { this.state.statesComponent === this.PANEL_SOMMAIRE ? (
            <Stack>
              <DefaultButton
                disabled = {!this.canAddToCart()}
                style={{
                  color: "white",
                  backgroundColor: this.canAddToCart()
                    ? this.props.theme.palette.blueDark
                    : this.props.theme.palette.neutralLight,
                  borderRadius: 10,
                  height: "50px",
                  width: this.props.mobile ? "150px" : "250px",
                }}
                onClick={() => {
                  this.addReservation();
                }}
              >
                {i18n.t("RPV:btn:AjoutPanier")}
              </DefaultButton>
            </Stack>
          ) : (
            <DefaultButton
              disabled={!this.canBeGoNextStep()}
              style={{
                color: "white",
                backgroundColor: this.canBeGoNextStep()
                  ? this.props.theme.palette.blueDark
                  : this.props.theme.palette.neutralLight,
                borderRadius: 10,
                height: "50px",
                width: this.props.mobile ? "150px" : "250px",
              }}
              onClick={() => {
                this.onNextComponent();
              }}
            >
              {i18n.t("RPV:btn:Suivant")}
            </DefaultButton>
          )}
        </Stack>
      </Stack>
    );
  }

  
  onNextComponent() {
    let value: number;
    let isLoading: boolean = false;
    if (this.state.statesComponent === this.PANEL_PORT_DEPART) {
      this.getServiceSupplementaire();
      this.getPassengers();
      this.getVehicles();
    }
    
    this.setState({
      statesComponent: this.getNextStepPanelNumber(this.state.statesComponent),
      isLoading: isLoading,
    });
  }

  async getDateDepart(portOrigineId: string, portDestinationId: string) {
    let dateDeparts: IComboBoxOption[] = [];
    let d = await VoyageServices.GetDeparts(portOrigineId, portDestinationId);
    d.forEach((x) => {
      dateDeparts.push({

          key: x.noPeriode,
          text: x.dateDepartLibelle,
          id: x.noVoyage
        
      });
    });
    this.setState({ dateDeDeparts: dateDeparts });
  }

  async addReservation() {
    LoadingServices.setLoading("AddReservation", true);
    let passagerIds: string[] = [];
    this.state.miniPassengerSelected.map((x) => {
      if (x.isCheck) {
        passagerIds.push(x.id.toString());
      }
    });
    await ReservationServices.AddReservation(
      parseInt(this.state.portOrigine.id?.toString()),
      parseInt(this.state.portDestination.id?.toString()),
      this.state.dateDeDepart.id,
      this.state.estAllerRetour,
      this._ssc,
      passagerIds,
      this._vsr,
      this.state.tutor
    ).then((x) => {
      if (x.code < 299) {
        this.props.updateCartInfo();
        this.setState({ reservationIsDone: true });
      }  else if (x.code > 399 && x.code < 499) {
        this.setState({ showModalError: true, modalErrorMsg: x.message });
      } 
    });
    LoadingServices.setLoading("AddReservation", false);
  }

  validationItineraryComplete(): boolean {
    if (
      this.state.portOrigine !== undefined &&
      this.state.portDestination !== undefined &&
      this.state.dateDeDepart !== undefined &&
      this.state.portOrigineDisponible && this.state.portDestinationDisponible
    ) {
      return true;
    } else {
      return false;
    }
  }

  canAddToCart(): boolean {
    return (
        !(this.state.vehicleSelectedReservation.filter((x) => x.selected).length > 0 &&
        this.state.informationSurreservation.nombrePlaceDisponible < this.state.vehicleSelectedReservation.filter(x=>x.selected).length)
      )
  }

  canBeGoNextStep(): boolean {
    switch (this.state.statesComponent) {
      case this.PANEL_PORT_DEPART:
        return this.validationItineraryComplete();
      case this.PANEL_PSG_VHC_SVC:
        return this.canGoToSummary();
      case this.PANEL_PSG_ONLY:
        return this.hasPassagerValideSelectionner() || this.hasClientVehicule()
      case this.PANEL_VHC_ONLY:
        return this.hasPassagerValideSelectionner() || this.hasVehiculeValideSelectionner()
      case this.PANEL_SVC_ONLY:
        return this.canGoToSummary();
      default:
        return true;
    }
  }

  canGoToSummary ()  {
    return (
      !this.state.onlyBaby &&
      (
        this.state.miniPassengerSelected.filter((x) => x.isCheck).length > 0 ||
        this.state.vehicleSelectedReservation.filter((x) => x.selected).length > 0
      ) &&
      (
        this.state.vehicleSelectedReservation.filter((x) => x.selected).length == 0 ||
        this.state.vehicleSelectedReservation.filter((x) => x.selected).length <= this.state.informationSurreservation.nombrePlaceDisponible
      ) &&
      this.validateCMDSelect() &&
      this.validateAssuranceSelect()
    );
  }

  renderMyPassenger() {
    return (
      <MyPassengers
        showErrorOnlyYoungChildSelected={this.state.onlyBaby}
        needTutor={this.state.onlyTeen}
        updateTutor={(value) => {
          this.setState({ tutor: value });
        }}
        {...this.props}
        forReservation={true}
        updateSelectPassenger={(p, v) => {
          let passengers: IMiniPassenger[] = [];
          passengers = this.state.miniPassengerSelected;
          passengers.filter((x) => x.id === p.id)[0].isCheck = v;

          this.setState({ miniPassengerSelected: passengers });
          this.validateAge();
        }}
        miniPassengerSelected={this.state.miniPassengerSelected}
      />
    );
  }

  validateCMDSelect() {
    let valid: boolean = true;
    this.state.vehicleSelectedReservation.forEach((x) => {
      if (x.selected !== x.neContientMatiereDangereuse
      ) {
        valid = false;
      }
    });
    return valid;
  }

  validateAssuranceSelect() {
    return this.state.vehicleSelectedReservation.every((x) => {
      if (!x.selected) {
        return true; 
      }
      if (x.estimeAssurance > 0 && x.infoAssurance === undefined) {
        return false; 
      }
      return true; 
    });
  }

  renderMyVehicule() {
    if (this.state.estAllerRetour) {
      return (
        <Stack>
          <span style={{color:"red" }}>{i18n.t("RPV:Message:NoVehiculeForRoundTrip")}</span>
        </Stack>
      )
    }

    if (this.state.informationSurreservation.nombrePlaceDisponible > 0){
      return (
        <Stack>
          <ReservationVehicle
            {...this.props}
            portOrigine={this.state.portOrigine.id}
            portDestination={this.state.portDestination.id}
            vehicleSelectedReservation={this.state.vehicleSelectedReservation}
            updateVehiculeCMD={(id, value) => {
              let vehicles: IVehiculeSelectedReservation[] =
                this.state.vehicleSelectedReservation;
              vehicles.filter(
                (x) => x.vehiculeId === id
              )[0].neContientMatiereDangereuse = value;
  
              this.setState({ vehicleSelectedReservation: vehicles });
            }}
            updateVehiculeInfoAssurance={(id, value) => {
              let vehicles: IVehiculeSelectedReservation[] =
                this.state.vehicleSelectedReservation;
              vehicles.filter(
                (x) => x.vehiculeId === id
              )[0].infoAssurance = value;
  
              this.setState({ vehicleSelectedReservation: vehicles });
            }}
            updateVehiculeEstimerAssurance={(id, value) => {
              let vehicles: IVehiculeSelectedReservation[] =
                this.state.vehicleSelectedReservation;
              vehicles.filter(
                (x) => x.vehiculeId === id
              )[0].estimeAssurance = value;
  
              this.setState({ vehicleSelectedReservation: vehicles });
            }}
            updateVehiculeSelect={(id, value) => {
              let vehicles: IVehiculeSelectedReservation[] =
                this.state.vehicleSelectedReservation;
  
              vehicles.filter((x) => x.vehiculeId === id)[0].selected = value;
  
              this.setState({ vehicleSelectedReservation: vehicles });
            }}
            updateVehiculeParticularite={(id, value) => {
              let vehicles: IVehiculeSelectedReservation[] =
                this.state.vehicleSelectedReservation;
              vehicles.filter((x) => x.vehiculeId === id)[0].particularite =
                value;
  
              this.setState({ vehicleSelectedReservation: vehicles });
            }}
            noPeriode={parseInt(this.state.dateDeDepart.key.toString())}
            informationSurreservation={this.state.informationSurreservation}
            getPlaceDispo={()=>this.PlaceRestante(
              parseInt(this.state.portOrigine.id),
              parseInt(this.state.portDestination.id),
              this.state.dateDeDepart.id
            )}
          />
        </Stack>
      );
    } else {
      return (<span style={{color:"red", marginTop:"20px"}}>{i18n.t("RPV:Message:NoPlaceLeft")}</span>)
    }
    
  }

  renderServiceSupplementaire() {
    return (
      <ServicesSupplementaire
        vehiculeSelected={
          this.state.vehicleSelectedReservation.filter((x) => x.selected)
            .length > 0
        }
        passagerSelected={
          this.state.miniPassengerSelected.filter((x) => x.isCheck).length > 0
        }
        updateAllServiceSupplementaire={(ss) => {
          this.setState({ servicesSuppCode: ss });
        }}
        serviceSupplementaires={this.state.servicesSuppCode}
        {...this.props}
        updateServiceSupplementaire={(serviceSupplementaire, value) => {
          let sscs = this.state.servicesSuppCode;
          sscs.filter((x) => x.code === serviceSupplementaire.code)[0].isCheck =
            value;

          this.setState({ servicesSuppCode: sscs });
        }}
      />
    );
  }

  setItemForSomaire() {
    this._ssc = [];
    this.state.servicesSuppCode?.forEach((x) => {
      if (x.isCheck) {
        this._ssc.push(x.code);
      }
    });

    this._vsr = [];
    this.state.vehicleSelectedReservation.forEach((x) => {
      if (x.selected) {
        this._vsr.push(x);
      }
    });

    this._mp = [];
    this.state.miniPassengerSelected.map((x) => {
      if (x.isCheck) {
        this._mp.push(x);
      }
    });
  }

  renderSommaire() {
    this.setItemForSomaire();    
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({
              showModalError: false
            });
          }}
          {...this.props}
          isOpen={this.state.showModalError}
          render={
            <Stack style={{ marginLeft: "25px", marginRight: "25px" }}>
              <Stack>
                <p>{this.state.modalErrorMsg}</p>
              </Stack>
            </Stack>
          }
        />
        <Ticketing
          {...this.props}
          tutor={this.state.tutor}
          miniPassengerSelected={this._mp}
          vehicleSelected={this._vsr}
          portOrigine={this.state.portOrigine}
          portDestination={this.state.portDestination}
          estAllerRetour={this.state.estAllerRetour}
          dateDeDepart={this.state.dateDeDepart}
          servicesSuppCode={this._ssc}
          informationSurreservation = {this.state.informationSurreservation}
          getPlaceDispo={()=>this.PlaceRestante(
            parseInt(this.state.portOrigine.id),
            parseInt(this.state.portDestination.id),
            this.state.dateDeDepart.id
          )}
          isLoading={() => {
            this.setState({ isLoading: false });
          }}
        />
      </Stack>
    );
  }

  async PlaceRestante(
    portOrigineId?: number,
    portDestinationId?: number,
    noVogage?: string
  ) {
    if (portOrigineId && portDestinationId && noVogage) {
      let param: IParametreSurreservation = {
        noVoyage: noVogage,
        noPortOrigine: portOrigineId,
        noPortDestination: portDestinationId,
        vehicules: [],
      }; 
      let info =
        await ReservationServices.GetVehiculeInformationSurrerservation(param);
        
      this.setState({ informationSurreservation: info });
    }
  }


  

  renderComponent(value: number) {
    switch (value) {
      case this.PANEL_PORT_DEPART:
        return (
          <InformationItinerary
            portOrigineDisponible={this.state.portOrigineDisponible}
            portDestinationDisponible={this.state.portDestinationDisponible}
            portOrigine={this.state.portOrigine}
            portDestination={this.state.portDestination}
            estAllerRetour={this.state.estAllerRetour}
            trajetOrigineDestinationSelect={
              this.state.portOrigine !== undefined && 
              this.state.portDestination !== undefined
            }
            dateDeDepart={this.state.dateDeDepart}
            dateDeDeparts={this.state.dateDeDeparts}
            
            updatePortOrigini={async (v) => {
              if (v !== undefined) {
                this.setState({ portOrigine: v, dateDeDepart: undefined, portDestinationDisponible: null, portOrigineDisponible: null });
                if (this.state.portDestination !== undefined) {
                  await this.getDateDepart(v.id, this.state.portDestination.id);
                  this.PlaceRestante(
                    parseInt(v.id),
                    parseInt(this.state.portDestination.id),
                    this.state.dateDeDepart?.id
                  );

                  if (parseInt(this.state.portOrigine.id) > parseInt(this.state.portDestination.id)){
                    this.setState({ estAllerRetour: false });                    
                  }
                }

              }
            }}
            updatePortDestination={async (v) => {
              if (v !== undefined) {
                this.setState({ portDestination: v, dateDeDepart: undefined, portDestinationDisponible: null, portOrigineDisponible: null });
                if (this.state.portOrigine !== undefined) {
                  await this.getDateDepart(this.state.portOrigine.id, v.id);
                  this.PlaceRestante(
                    parseInt(this.state.portOrigine.id),
                    parseInt(v.id),
                    this.state.dateDeDepart?.id
                  );
                    
                  if (parseInt(this.state.portOrigine.id) > parseInt(this.state.portDestination.id)){
                    this.setState({ estAllerRetour: false });                    
                  }
                }
              }
            }}
            updateEstAllerRetour={(v) => {
              if (v !== undefined) {
                this.setState({ estAllerRetour: v, dateDeDepart: undefined, portDestinationDisponible: null, portOrigineDisponible: null  });
              }
            }}
            updateDateDeDepart={(v) => {
              if (v !== undefined) {
                const d = this.state.dateDeDeparts.find(x=>x.id === v.id)
                this.setState({ dateDeDepart: d}, ()=>this.SetItineraryDisponibilite());                
                this.PlaceRestante(
                  parseInt(this.state.portOrigine.id),
                  parseInt(this.state.portDestination.id),
                  v.id
                );
              }
            }}
            informationSurreservation={this.state.informationSurreservation}
            {...this.props}
          />
        );
        break;
      case this.PANEL_PSG_VHC_SVC:
        return (
          <Stack>
              <Stack>{this.renderMyPassenger()}</Stack>
              <Stack style={{ marginTop: "20px" }}>
                {this.renderMyVehicule()}
              </Stack>
              <Stack
                style={{
                  marginTop: "20px",
                  marginLeft: "15px",
                  minHeight: "220px",
                }}
                >
                {this.state.miniPassengerSelected.filter((x) => x.isCheck)
                  .length > 0
                  ? this.renderServiceSupplementaire()
                  : ""}
              </Stack>
            </Stack>
          );
      case this.PANEL_PSG_ONLY:
          return this.renderMyPassenger();
      case this.PANEL_VHC_ONLY:
        return this.renderMyVehicule();
      case this.PANEL_SVC_ONLY:
        if (this.canBeGoNextStep()) {
          return this.renderServiceSupplementaire();
        } else {
          this.setState({ statesComponent: this.state.statesComponent + 1 });
        }
        break;
      case this.PANEL_SOMMAIRE:
        return this.renderSommaire();
      default:
        break;
    }
  }

  async getVehicles() {
    LoadingServices.setLoading("getVehicles", true);

    let v = await ReservationServices.GetVehiculeDisponnible(
      parseInt(this.state.dateDeDepart.key.toString())
    );
    let vehicleSelectedReservation: IVehiculeSelectedReservation[] = [];
    if (!v.delaiDepasserPourVehicule) {
      v.listeVehicules.forEach((x) => {
        if (!x.enAttenteApprobation) {
          let vsr: IVehiculeSelectedReservation = {
            vehiculeId: x.id,
            neContientMatiereDangereuse: false,
            particularite: "",
            showParticularity: false,
            selected: false,
            vehiculeLibelle: x.vehiculeLibelle,
          };
          vehicleSelectedReservation.push(vsr);
        }
      });
    }
    this.setState({
      vehicleSelectedReservation: vehicleSelectedReservation,
    });
    LoadingServices.setLoading("getVehicles", false);
  }

  async getServiceSupplementaire() {
    LoadingServices.setLoading("ServicesSupplementaire", true);
    let ss = await ReservationServices.GetServicesSupplementaires( 
      parseInt(this.state.portOrigine.id),
      parseInt(this.state.portDestination.id));

    this.setState({ servicesSuppCode: ss });

    LoadingServices.setLoading("ServicesSupplementaire", false);
  }

  async componentDidMount() {
    const cookies = new Cookies();
    let ii = cookies.get("infoItineraire");
    if (ii !== undefined) {
      await this.getDateDepart(ii.portOrigine.id, ii.portDestination.id);
      await this.PlaceRestante(ii.portOrigine.id, ii.portDestination.id, ii.dateDeDepart.id);
      this.setState({
        portDestination: ii.portDestination,
        portOrigine: ii.portOrigine,
        dateDeDepart: ii.dateDeDepart,
        estAllerRetour : ii.estAllerRetour
      }, ()=>this.SetItineraryDisponibilite());     
      cookies.remove("infoItineraire");
    }
    this.setState({ isLoading: false });
  }

  async getPassengers() {
    LoadingServices.setLoading("getPassengersRP", true);
    let miniPassenger: IMiniPassenger[] = [];
    let v = await ReservationServices.GetClientPassager(
      parseInt(this.state.portOrigine.id),
      parseInt(this.state.portDestination.id),
      parseInt(this.state.dateDeDepart.key.toString())
    );
    v.forEach((x) => {
      miniPassenger.push(x);
    });
    this.setState({ miniPassengerSelected: miniPassenger, isLoading: false });

    LoadingServices.setLoading("getPassengersRP", false);
  }

  async SetItineraryDisponibilite() {
    if (this.state.portOrigine && this.state.portDestination && this.state.dateDeDepart ){

      if (this.state.estAllerRetour) {
        const infoDisponibiliteAller = await PortServices.GetDisponibilitePorts(
        this.state.portOrigine.key.toString(),
        this.state.portDestination.key.toString(),
        this.state.dateDeDepart.id)
          
        const infoDisponibiliteRetour = await PortServices.GetDisponibilitePorts(
          this.state.portDestination.key.toString(),
          this.state.portOrigine.key.toString(),
          this.state.dateDeDepart.id)

          const estPortOrigineDisponible = (infoDisponibiliteAller.estPortOrigineDisponible && infoDisponibiliteRetour.estPortDestinationDisponible)
          const estPortDestinationDisponible = (infoDisponibiliteAller.estPortDestinationDisponible && infoDisponibiliteRetour.estPortOrigineDisponible)

          this.setState({portOrigineDisponible: estPortOrigineDisponible, portDestinationDisponible: estPortDestinationDisponible})        


      } else {
        const infoDisponibilite = await PortServices.GetDisponibilitePorts(
          this.state.portOrigine.key.toString(),
          this.state.portDestination.key.toString(),
          this.state.dateDeDepart.id)
          this.setState({portOrigineDisponible: infoDisponibilite.estPortOrigineDisponible, portDestinationDisponible: infoDisponibilite.estPortDestinationDisponible})
      }

      
    } else {
      return false
    }
  }

  validateAge() {
    let mp = this.state.miniPassengerSelected.filter((x) => x.isCheck);
    let underTwo = 0;
    let twoToSixteen = 0;
    let adult = 0;

    let onlyBaby = false;
    let onlyTeen = false;

    if (mp.length === 0) {
      this.setState({ onlyTeen: onlyTeen, onlyBaby: onlyBaby });
    } else {
      mp.forEach((x) => {
        if (x.categorieAgeCode === "MOINS_2_ANS") {
          underTwo++;
        } else if (x.categorieAgeCode === "MOINS_16_ANS") {
          twoToSixteen++;
        } else {
          adult++;
        }
      });

      if (underTwo > 0 && adult === 0) {
        onlyBaby = true;
      }
      if (twoToSixteen > 0 && adult === 0) {
        onlyTeen = true;
      }

      this.setState({ onlyTeen: onlyTeen, onlyBaby: onlyBaby });
    }
  }

  getNextStepPanelNumber(currentPanel: number){
    let result = -1;
    
    switch (currentPanel) {
      case this.PANEL_PORT_DEPART: 
        result = (this.props.mobile) ? this.PANEL_PSG_ONLY : this.PANEL_PSG_VHC_SVC   
        break;

      case this.PANEL_PSG_ONLY:
        result = this.PANEL_VHC_ONLY      
        break
      case this.PANEL_VHC_ONLY:
        result = this.PANEL_SVC_ONLY     
        break
      case this.PANEL_SVC_ONLY:
        result = this.PANEL_SOMMAIRE    
        break

      case this.PANEL_PSG_VHC_SVC:
        result = this.PANEL_SOMMAIRE   
        break

      case this.PANEL_SOMMAIRE:        
      default:
        result = -1;
    }

    return result;
  }

  getPreviousStepPanelNumber(currentPanel: number){
    let result = -1;
    
    switch (currentPanel) {
      case this.PANEL_SOMMAIRE:  
        result = (this.props.mobile) ? this.PANEL_SVC_ONLY : this.PANEL_PSG_VHC_SVC       
        break

      case this.PANEL_SVC_ONLY:
        result = this.PANEL_VHC_ONLY      
        break
      case this.PANEL_VHC_ONLY:
        result = this.PANEL_PSG_ONLY     
        break
      case this.PANEL_PSG_ONLY:
        result = this.PANEL_PORT_DEPART    
        break

      case this.PANEL_PSG_VHC_SVC:
        result = this.PANEL_PORT_DEPART   
        break   

      case this.PANEL_PORT_DEPART: 
      default:
        result = -1;
    }

    return result;
  }

  hasPassagerValideSelectionner () {
    return !this.state.onlyBaby && this.state.miniPassengerSelected.filter((x) => x.isCheck).length > 0
  }

  hasVehiculeValideSelectionner () {
    return this.state.vehicleSelectedReservation.filter((x) => x.selected).length > 0 &&
      this.state.vehicleSelectedReservation.filter((x) => x.selected).length <= this.state.informationSurreservation.nombrePlaceDisponible &&
      this.validateCMDSelect() &&
      this.validateAssuranceSelect()
  }

  hasClientVehicule () {
    return this.state.vehicleSelectedReservation.length > 0 // vehicleSelectedReservation contient tous les véhicules, coché ou pas
  }

  hasClientPassager () {
    return this.state.miniPassengerSelected.length > 0 // miniPassengerSelected contient tous les psg, coché ou pas
  }
}
