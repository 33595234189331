import axios from "axios";
import { IPort } from "../Models/IPort";
import { apiUrl } from "./ApiService";
import { IDisponibilitePortsDto } from "../Models/IDisponibilitePortsDto";
export class PortServices {
  public static async GetPorts(): Promise<IPort[]> {
    let result: any | undefined;
    try {
      const x = await axios.get<any>(`${apiUrl}/api/port/get-ports`);
      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetDisponibilitePorts( portOrigine: string, portDestionation: string, noVoyage:  string): Promise<IDisponibilitePortsDto> {
    let result: any | undefined;
    try {
      const x = await axios.get<any>(`${apiUrl}/api/port/valider-disponibilite-ports/${portOrigine}/${portDestionation}/${noVoyage}`);
      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}

