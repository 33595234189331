import {
  Checkbox,
  ComboBox,
  Dropdown,
  IComboBoxOption,
  Label,
  Stack,
} from "@fluentui/react";
import * as React from "react";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { PortServices } from "../../Services/PortServices";
import { StylingServices } from "../../Services/StylingServices";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  IInformationItineraryProps,
  IInformationItineraryState,
} from "./InformationItinerary.types";

export class InformationItineraryComponent extends React.Component<
  IInformationItineraryProps,
  IInformationItineraryState
> {
  constructor(props: IInformationItineraryProps) {
    super(props);
    this.state = {
      ports: [],
      info: null
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("getPort", true);

    let p = await PortServices.GetPorts();
    let ports: IComboBoxOption[] = [];
    p.forEach((x) => {
      ports.push({
        key: x.code,
        text: x.libelle,
        id: x.noPort.toString(),
      });
    });
    this.setState({ ports: ports });
    LoadingServices.setLoading("getPort", false);
  }

  canDoAllerRetour(): boolean {
    let noPortOrigine =
      this.props.portOrigine !== undefined
        ? parseInt(this.props.portOrigine.id)
        : 0;
    let noPortDestination =
      this.props.portDestination !== undefined
        ? parseInt(this.props.portDestination.id)
        : 0;
    return noPortOrigine < noPortDestination;
  }

  getItineraireIndisponibleMessage() {
    const { portOrigineDisponible, portDestinationDisponible, portOrigine, portDestination } = this.props;

    if (!portOrigine || !portDestination || portDestinationDisponible===null || portOrigineDisponible===null)
      return null

    if (!portOrigineDisponible && !portDestinationDisponible) {
      return i18n.t("IF:Titre:ItineraireNonDisponibleBoth")
        .replace("{0}", portOrigine.text)
        .replace("{1}", portDestination.text);
    }
    if (!portOrigineDisponible) {
      return i18n.t("IF:Titre:ItineraireNonDisponibleSingle").replace("{0}", portOrigine.text);
    }
    if (!portDestinationDisponible) {
      return i18n.t("IF:Titre:ItineraireNonDisponibleSingle").replace("{0}", portDestination.text);
    }
    return null;
  }

  render(): JSX.Element {
    let color = "red";

    if (this.props.informationSurreservation !== null && this.props.informationSurreservation !== undefined) {
      color = StylingServices.GetGradationColor(this.props.informationSurreservation.nombrePlaceUtilise, this.props.informationSurreservation.nombrePlaceMaximum)
    }

    let afficher = false;
    if(this.props.informationSurreservation !== undefined && this.props.informationSurreservation !== null )
    {
      afficher = true;
    }
    
    const messagePortNonDispo = this.getItineraireIndisponibleMessage();

    return (      
      <Stack>
        {afficher ? (
          <Stack style={{ marginTop: "15px" }}>
            <Label style={{ color: color }}>
            {i18n.t("IF:Titre:NombrePlaceDisponiblePartie1")} 
              {this.props.informationSurreservation.nombrePlaceDisponible}
              {i18n.t("IF:Titre:NombrePlaceDisponiblePartie2")} 


            </Label>
          </Stack>
        ) : (
          ""
        )}
        {messagePortNonDispo && (
          <Stack style={{ marginTop: "10px" }}>
            <Label style={{ color: "red" }}>{messagePortNonDispo}</Label>
          </Stack>
        )}
        {this.props.mobile ? this.renderMobile() : this.renderDesktop()}
        {this.renderBaseMessage()}
      </Stack>
    );
  }

  renderMobile(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("IF:Titre:InformationIntineraire")}
        isMobile={true}
        render={
          <Stack style={{ width: "90%", margin: "15px" }}>
            <Stack>
              <Label>{i18n.t("IF:Label:PortOrigine")}</Label>
              {this.renderSelectPortOrigineMobile()}
            </Stack>
            <Stack>
              <Label style={{ marginTop: "10px" }}>
                {i18n.t("IF:Label:PortDestination")}
              </Label>
              {this.renderSelectPortDestinationMobile()}
            </Stack>

            {this.renderSelectAllerRetour()}
            {this.props.trajetOrigineDestinationSelect ? this.renderSelectDateMobile() : ""}
            {this.props.forCalculator ? this.renderIsResident() : ""}
          </Stack>
        }
      />
    );
  }
  renderIsResident() {
    return (
      <Stack
        horizontal
        style={{
          marginTop: this.props.mobile ? "10px" : "",
          marginRight: "20px",
        }}
      >
        <Checkbox
          checked={this.props.estResident}
          label={i18n.t("IF:Label:isResident")}
          onChange={(e, v) => {
            this.props.updateIsResident(v);
          }}
        />
      </Stack>
    );
  }

  renderSelectPortOrigineDesktop() {
    return (
      <ComboBox
        options={this.state.ports.filter(
          (x) =>
            x?.key.toString() !== this.props.portDestination?.key.toString()
        )}
        selectedKey={
          this.props.portOrigine !== undefined ? this.props.portOrigine.key : ""
        }
        onChange={(e, v) => {
          if (v !== undefined) {
            this.props.updatePortOrigini(v);
          }
        }}
      />
    );
  }

  renderSelectPortOrigineMobile() {
    return (
      <Dropdown
        options={this.state.ports.filter(
          (x) =>
            x?.key.toString() !== this.props.portDestination?.key.toString()
        )}
        selectedKey={
          this.props.portOrigine !== undefined ? this.props.portOrigine.key : ""
        }
        onChange={(e, v) => {
          if (v !== undefined) {
            this.props.updatePortOrigini(v);
          }
        }}
      />
    );
  }

  renderSelectPortDestinationDesktop() {
    return (
      <ComboBox
        dropdownWidth={200}
        useComboBoxAsMenuWidth={true}
        style={{ marginTop: "10px" }}
        selectedKey={
          this.props.portDestination !== undefined
            ? this.props.portDestination.key
            : ""
        }
        options={this.state.ports.filter(
          (x) => x?.key.toString() !== this.props.portOrigine?.key.toString()
        )}
        onChange={async (e, v) => {
          if (v !== undefined) {
            this.props.updatePortDestination(v);
          }
        }}
      />
    );
  }

  renderSelectPortDestinationMobile() {
    return (
      <Dropdown
        dropdownWidth={200}
        
        style={{ marginTop: "10px" }}
        selectedKey={
          this.props.portDestination !== undefined
            ? this.props.portDestination.key
            : ""
        }
        options={this.state.ports.filter(
          (x) => x?.key.toString() !== this.props.portOrigine?.key.toString()
        )}
        onChange={async (e, v) => {
          if (v !== undefined) {
            this.props.updatePortDestination(v);
          }
        }}
      />
    );
  }

  renderSelectAllerRetour() {
    return (
      <Stack horizontal style={{ marginTop: this.props.mobile ? "10px" : "" }}>
        <Checkbox
          checked={this.canDoAllerRetour() && this.props.estAllerRetour}
          label={i18n.t("IF:Label:AllerRetour")}
          disabled={!this.canDoAllerRetour()}
          onChange={(e, v) => {
            this.props.updateEstAllerRetour(v);
          }}
        />
      </Stack>
    );
  }

  renderSelectDateDesktop() {
    return (
      <Stack
        horizontal={!this.props.mobile}
        style={{
          marginLeft: this.props.mobile ? "" : "45px",
          marginTop: this.props.mobile ? "10px" : "",
          minHeight: this.props.mobile ? "85px" : "",
        }}
      >
        <Label style={{ marginRight: "15px" }}>
          {i18n.t("IF:Label:DateDeDepart")}
        </Label>

        <ComboBox
          dropdownWidth={200}
          options={this.props.dateDeDeparts}
          selectedKey={
            this.props.dateDeDepart !== undefined
              ? this.props.dateDeDepart.key
              : ""
          }
          onChange={(e, v) => {
            if (v !== undefined) {
              this.props.updateDateDeDepart(v);
            }
          }}
        />
        {this.props.dateDeDepart ? (
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              marginLeft: this.props.mobile ? "" : "15px",
            }}
          >
            {i18n.t("IF:Label:Voyage")} {this.props.dateDeDepart?.id}
          </Label>
        ) : (
          ""
        )}
      </Stack>
    );
  }

  renderSelectDateMobile() {
    return (
      <Stack
        horizontal={!this.props.mobile}
        style={{
          marginLeft: this.props.mobile ? "" : "45px",
          marginTop: this.props.mobile ? "10px" : "",
          minHeight: this.props.mobile ? "85px" : "",
        }}
      >
        <Label style={{ marginRight: "15px" }}>
          {i18n.t("IF:Label:DateDeDepart")}
        </Label>

        <Dropdown
          // style={{width:200}}
          dropdownWidth={200}
          options={this.props.dateDeDeparts}
          selectedKey={
            this.props.dateDeDepart !== undefined
              ? this.props.dateDeDepart.key
              : ""
          }
          onChange={(e, v) => {
            if (v !== undefined) {
              this.props.updateDateDeDepart(v);
            }
          }}
        />
        {this.props.dateDeDepart ? (
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              marginLeft: this.props.mobile ? "" : "15px",
            }}
          >
            {i18n.t("IF:Label:Voyage")} {this.props.dateDeDepart?.id}
          </Label>
        ) : (
          ""
        )}
      </Stack>
    );
  }

  renderDesktop() {
    return (
      <Stack>
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={i18n.t("IF:Titre:InformationIntineraire")}
          render={
            <Stack style={{ marginBottom: "20px" }}>
              <Stack
                horizontalAlign="start"
                style={{ marginLeft: "20px", marginTop: "40px" }}
                verticalAlign="center"
                horizontal
              >
                <Stack style={{ marginLeft: "25px" }}>
                  <Label>{i18n.t("IF:Label:PortOrigine")}</Label>
                  <Label style={{ marginTop: "10px" }}>
                    {i18n.t("IF:Label:PortDestination")}
                  </Label>
                </Stack>
                <Stack style={{ marginLeft: "25px", width: "35%" }}>
                  {this.renderSelectPortOrigineDesktop()}
                  {this.renderSelectPortDestinationDesktop()}
                </Stack>
              </Stack>
              <Stack
                horizontal
                style={{
                  marginLeft: "45px",
                  marginTop: "40px",
                  marginBottom: "20px",
                }}
                verticalAlign="center"
              >
                {this.props.forCalculator ? this.renderIsResident() : ""}
                {this.renderSelectAllerRetour()}
                {this.props.trajetOrigineDestinationSelect ? this.renderSelectDateDesktop() : ""}
              </Stack>
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderBaseMessage(){
    return (
      <Stack
            style={{
              border: "1px solid black",
              borderRadius: 30,
              width: this.props.mobile ? "95%" : "94%",
              marginTop: "25px",
              marginLeft: this.props.mobile ? "10px" : "20px",
              paddingTop: "25px",
              paddingBottom: "25px",
              backgroundColor: this.props.theme.palette.blueDark,
            }}
          >

            <Label
              style={{
                color: "white",
                marginLeft: "20px",
                marginRight: "20px",
                textAlign:"justify", 
                textJustify:"inter-word"
              }}
            >
              - {i18n.t("RPV:Message:PassageMaritimeOnly")}
            </Label>

          </Stack>
    )
  }

}
